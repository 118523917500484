/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bellIcon,
  chatIcon,
  logo,
  notificationCircle,
  uploadImg,
  bellIconBlue,
  whiteArrowDown,
  calendarBlue,
  calendarWhite,
  redWarning
} from "../../assets/images";
import { MenuLinks } from "../../constants/MenuLinks";
import { DownloadDocGet } from "../../services/portfolio";
import { getToken, getWebRole } from "../../util/localStorage";
import { GetUserInfo, GetUserLoginData } from "../../util/reduxData";
import { HelpCenterURL } from "../../constants/legacySiteURL";
import { MyInformationGetAPI } from "../../services/myInformation";
import { GetNotification } from "../../services/notifications";
import {
  getMyInformation,
  updateProfileImage,
} from "../../ducks/myInformation";
import MobileNavbar from "./MobileNavbar";
import NavbarPopupMenu from "../navbarPopupMenu/NavbarPopupMenu";
import Button from "../button";
import RedirectConfirmModal from "../redirectConfirmModal";
import NavbarNotificationMenu from "../navbarPopupNotificatoons/navbarPopupNotificatoons.jsx";
import SelectSplittedRole from "../selectSplittedRole";
import Modal from "../modal";
import LoginModal from "../../screens/Authentication/LoginModal";
import ForceChangePassword from "../../screens/Authentication/ForcePassowordChange";
import "./Navbar.css";

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

let Navbar = () => {
  const token = getToken();
  const ref = useRef();
  const [count, setCount] = useState();

  const notificationState = useSelector(
    (state) => state.toggleNotificationExitReducer.exit
  );

  useEffect(() => {
    if (token) {
      getNotification();
    }
  }, [notificationState])

  useEffect(() => {
    window.scroll(0, 0);
    if (token) {
      getMyInformationAPI();
    }
  }, []);

  const getNotification = () => {
    GetNotification("T").then((response) => {
      const data = response?.data?.filter((data) => {
        if (data.read === false) {
          return data;
        }
      });

      setCount(data.length);
    });
  };
  const showProfilePic = (guid) => {
    DownloadDocGet(guid).then((response) => {
      dispatch(
        updateProfileImage(
          `${new Buffer(response.data, "binary").toString("base64")}`
        )
      );
    });
  };

  const location = useLocation();

  useEffect(() => {
    return () => {
      sessionStorage.setItem('lastLocation', location.pathname);
    }
  }, [location.pathname])

  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openRedirectModalHelp, setOpenRedirectModalHelp] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
   const queryString = history.location.search
  const hash = new URLSearchParams(queryString).get("hash");
  const user = GetUserInfo();
  const userData = GetUserLoginData()
  let [openNav, setOpenNav] = useState(false);
  const [openNavDropdown, setOpenNavDropdown] = useState(false);
  const [openNotification, setopenNotification] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [openNav]);

  let getMyInformationAPI = () => {
    try {
      const res = MyInformationGetAPI(userData.instrId)
        .then((response) => {
          dispatch(getMyInformation(response.data));
          if (response.data?.thumbnailBlobId) {
            dispatch(updateProfileImage(user?.imageAsBase64String));
            showProfilePic(response.data?.thumbnailBlobId);
          } else {
            dispatch(updateProfileImage(""));
          }
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.err("err", err);
    }
  };

  let closeNav = () => {
    setOpenNav(false);
  };
  const handleOutsideClick = (event, ref) => {
    if (
      ref.current &&
      !ref.current.contains(event.target)
    ) {
      setOpenNavDropdown(false);
    }
  };

  useOnClickOutside(ref, () => setopenNotification(!openNotification));

  const handleLinkClick = () => {
    setOpenNavDropdown(false);
  };

  const handleLinkClickNotification = () => {
    setopenNotification(false);
  };

  const auth = getToken();

  const getLoginData = GetUserLoginData()
  const [forceChangePassword, setForcePasswordChange] = useState(false)

  useLayoutEffect(() => {
    if (getLoginData.forceChangePassword > 0) {
      setForcePasswordChange(true)
    } else {
      setForcePasswordChange(false)
    }
  }, [getLoginData])

  return (
    <React.Fragment>
      <LoginModal isOpen={openLoginModal} toggleModal={() => setOpenLoginModal(!openLoginModal)} />
      {auth &&
        <Modal
          isOpen={forceChangePassword}
          size="extrasmall"
          hideCloseButton={false}
          toggleModal={() => { }}
          disableBackdropToggle={true}
          containerClass={"addTimeLineModal"}
          classname="z102"
          title={
            <div className="ml-2">
              <img src={redWarning} alt="warning" className="pb-1" />
              <span className="boldFont font18Black ml-2" >Change Password</span>
            </div>
          }>
          <ForceChangePassword />
        </Modal>}
      {
        userData?.showTrainerRole &&
        <SelectSplittedRole />
      }
      <RedirectConfirmModal
        isOpen={openRedirectModal}
        toggleModal={() => setOpenRedirectModal(!openRedirectModal)}
        redirectUrl={
          "https://ttaconnect.ttacorp.com/TrainingProfessionals/MyJobs.aspx"
        }
      />
      <RedirectConfirmModal
        isOpen={openRedirectModalHelp}
        toggleModal={() => setOpenRedirectModalHelp(!openRedirectModalHelp)}
        redirectUrl={HelpCenterURL}
      />
      <div
        className={
          !auth
            ? "container-fluid newNavbarContainer d-flex align-items-center"
            : (user?.nickName?.length || user?.firstName?.length) < 4
              ? "container-fluid newNavbarContainer d-flex align-items-center"
              : "container-fluid newNavbarContainer d-flex align-items-center"
        }
      >
        {history.location.pathname.includes("/submit-testimonial/") ||
          history.location.pathname.includes("/submit-testimonial-confirm") ? (
          <div className="d-none"></div>
        ) : (
          <div
            className={
              openNav ? "mobileMenuBtn mobileMenuBtnActive" : "mobileMenuBtn"
            }
            onClick={() => setOpenNav(!openNav)}
          >
            <div className="my-1 menuLines" />
            <div className="my-1 menuLines" />
            <div className="my-1 menuLines" />
          </div>
        )}


        <div className="">
          {history.location.pathname.includes("/submit-testimonial/") ||
            history.location.pathname.includes("/submit-testimonial-confirm") ? (
            <a
              href={"https://thetrainingassociates.com/"}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="NavbarBrandLogo" src={logo} alt="logo" />
            </a>
          ) : (
            <Link to={auth ? "/user/dashboard" : "/"}>
              <img className="NavbarBrandLogo" src={logo} alt="logo" />
            </Link>
          )}
        </div>
        { auth && (
          <React.Fragment>
            <div className="d-flex align-items-center">
              {MenuLinks.map((links, index) => (
                <React.Fragment key={index}>
                  {
                    links.name === "My Jobs" ?
                      <a
                        className={
                          location.pathname === links.path ||
                            location.pathname.includes(links?.active)
                            ? "NewNavBarLinks NewNavBarLinksActive"
                            : "NewNavBarLinks"
                        }
                        onClick={() => setOpenRedirectModal(true)}
                        rel="noopener noreferrer"
                      >
                        {links.name}{" "}
                      </a>
                      :
                      <>
                        {!links.mobile && (
                          <>
                            {links.legacy ? (
                              <>
                                {
                                  getLoginData.forceChangePassword === 0 ?
                                    <a
                                      href={links.path}
                                      key={links.name}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={
                                        location.pathname === links.path ||
                                          location.pathname.includes(links?.active)
                                          ? "NewNavBarLinks NewNavBarLinksActive"
                                          : "NewNavBarLinks"
                                      }
                                    >
                                      {links.name}
                                    </a> :
                                    <div className={
                                      location.pathname === links.path ||
                                        location.pathname.includes(links?.active)
                                        ? "NewNavBarLinks NewNavBarLinksActive"
                                        : "NewNavBarLinks"
                                    }>
                                      {links.name}
                                    </div>
                                }
                              </>
                            ) : (
                              <>
                                {
                                  links.name === "My Tasks" && history.location.pathname === links.path ?
                                    <Link
                                      to={{
                                        pathname: links.path,
                                        search: "?view=list",
                                      }}
                                      key={links.name}
                                      className={"NewNavBarLinks NewNavBarLinksActive"}
                                    >
                                      {!getLoginData?.agencyInstrId ? "My Tasks" : "Tasks"}
                                    </Link>
                                    : <Link
                                      to={links.path}
                                      key={links.name}
                                      className={
                                        location.pathname === links.path ||
                                          (location.pathname.includes("jobcenter") && links.path.includes("jobcenter"))
                                          ? "NewNavBarLinks NewNavBarLinksActive"
                                          : "NewNavBarLinks"
                                      }
                                    >
                                      {

                                        links.name === "My Tasks" ?
                                        !getLoginData?.agencyInstrId ? "My Tasks" : "Tasks"
                                          :
                                          (!userData.agencyInstrId && links.name === "Profiles") ?
                                            "My Profile" :
                                            links.name
                                      }
                                    </Link>
                                }
                              </>
                            )}
                          </>
                        )}
                      </>
                  }
                </React.Fragment>
              ))}
            </div>
            <div className="ml-auto">
              <div className="d-flex align-items-center">
                {auth && (
                  <a
                    className={"desktopView mr-4 font14Blue noUnderline"}
                    href={"https://thetrainingassociates.com/?utm_source=TTA+Connect+&utm_medium=TTAConnectMainNav&utm_campaign=Corplink"}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Go to TTA
                  </a>
                )}
                <div className="navbarnotificationWrapper mr-3">
                  {location.pathname === "/calendar-view" ? (
                    <img
                      src={calendarBlue}
                      className="bellIcon"
                      onClick={() => {
                        closeNav();
                        history.push({
                          pathname: "/calendar-view",
                          state: { hideBack: true },
                          search: "?view=day"
                        })
                      }}
                      alt=""
                      id="calendar"
                    />
                  ) : (
                    <img
                      src={calendarWhite}
                      className="bellIcon"
                      onClick={() => {
                        closeNav();
                        history.push({
                          pathname: "/calendar-view",
                          state: { hideBack: true },
                          search: "?view=day"
                        })
                      }}
                      alt=""
                      id="calendar"
                    />
                  )}
                </div>


                <div className="navbarnotificationWrapper">
                  {location.pathname === "/notificationviewall" ? (
                    <img
                      src={bellIconBlue}
                      className="bellIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setopenNotification(
                          (openNotification) => !openNotification
                        );
                      }}
                      alt=""
                      id="notifications"
                    />
                  ) : (
                    <img
                      src={bellIcon}
                      className="bellIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setopenNotification(
                          (openNotification) => !openNotification
                        );
                      }}
                      alt=""
                      id="notifications"
                    />
                  )}
                  {location.pathname === "/notificationviewall" ? (
                    <div className="d-none"></div>
                  ) : (
                    count > 0 && (
                      <div className="notificationSectionCountWrapper">
                        <div className="notificationCountNeww" onClick={(e) => {
                          e.stopPropagation();
                          setopenNotification(
                            (openNotification) => !openNotification
                          );
                        }}>{count}</div>
                      </div>
                    )
                  )}
                  {openNotification && getLoginData.forceChangePassword === 0 && (
                    <>
                      <div className="traingle"></div>
                      <div className="overlayNavbar" ref={ref}>
                        <NavbarNotificationMenu
                          handleLinkClick={handleLinkClickNotification}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="chatIconSection d-none">
                  <img src={chatIcon} alt="" />
                  <div className="notificationSection">
                    <div style={{ position: "relative" }}>
                      <img
                        src={notificationCircle}
                        className="notificationCircle"
                        alt=""
                      />
                      <span className="notificationCount">1</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="border-right ml-2 mr-3 borderRightSpan">.</span>
            <img
              src={
                user?.imageAsBase64String
                  ? "data:image/jpeg;base64,".concat(user?.imageAsBase64String)
                  : uploadImg
              }
              onClick={() => setOpenNavDropdown(!openNavDropdown)}
              className="userIconImg"
              alt=""
              id="dropdown"
            />
            {openNavDropdown && getLoginData.forceChangePassword !== 1 && (
              <NavbarPopupMenu
                handleOutsideClick={handleOutsideClick}
                handleLinkClick={handleLinkClick}
              />
            )}
            <span
              className="NewNavBarLinks px-0 ml-2 text-capitalize"
              onClick={() => setOpenNavDropdown(!openNavDropdown)}
            >
              {user?.nickName?.substr(0, 15) || user?.firstName?.substr(0, 15)}
              <img src={whiteArrowDown} alt="" className="rotateArrow" />
            </span>
          </React.Fragment>
        )}
        
        {!auth && hash && (
          /* In case of public link including a hash, display "fake" top menu*/
          <React.Fragment>

            <div className="d-flex align-items-center">
              {MenuLinks.map((links, index) => (
                <React.Fragment key={index}>
                  {
                    links.name === "My Tasks" && history.location.pathname === links.path ?
                      <Link
                        to={"#"}
                        onClick={(e) =>{e.preventDefault(); setOpenLoginModal(true)}}
                        key={links.name}
                        className={"NewNavBarLinks NewNavBarLinksActive"}
                      >
                        {!getLoginData?.agencyInstrId ? "My Tasks" : "Tasks"}
                      </Link>
                      : <Link
                        to={"#"}
                        onClick={(e) =>{e.preventDefault(); setOpenLoginModal(true)}}
                        key={links.name}
                        className={
                          location.pathname === links.path ||
                            (location.pathname.includes("jobcenter") && links.path.includes("jobcenter"))
                            ? "NewNavBarLinks NewNavBarLinksActive"
                            : "NewNavBarLinks"
                        }
                      >
                        {

                          links.name === "My Tasks" ?
                          !getLoginData?.agencyInstrId ? "My Tasks" : "Tasks"
                            :
                            (!userData.agencyInstrId && links.name === "Profiles") ?
                              "My Profile" :
                              links.name
                        }
                      </Link>
                  }
                </React.Fragment>
              ))}
            </div>

          </React.Fragment>
        )}
        {!auth && (
          <>
            <a
              className={"NewNavBarLinks whiteText noUnderline ml-auto tta-link"}
              href={"https://thetrainingassociates.com/?utm_source=TTA+Connect+&utm_medium=TTAConnectMainNav&utm_campaign=Corplink"}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more about TTA
            </a>
            <span
              className={
                  history.location.pathname.includes("/submit-testimonial/")
                  || history.location.pathname.includes("/submit-testimonial-confirm")
                  || history.location.pathname.includes("/login")
                  || history.location.pathname.includes("/talent/")
                  ? "d-none"
                  : " NewNavBarLinks whiteText nav-login"
              }
              label="Log in"
              onClick={() => {
                history.push("/login")
              }}
            >
              Log in
            </span>


            <span
              className={
                  history.location.pathname.includes("/submit-testimonial/")
                  || history.location.pathname.includes("/submit-testimonial-confirm")
                  || history.location.pathname.includes("/login")
                  || history.location.pathname.includes("/talent/")
                  ? "d-none"
                  : " mobileView nav-login-mobile whiteText "
              }
              label="Log in"
              onClick={() => {
                history.push("/login")
              }}
            >
              Log in
            </span>

            {
              history.location.pathname.includes("/talent/")
               && (
                <>
                    <Button
                      label="Log in"
                      type="submit"
                      onClick={() => {
                        history.push("/login");
                      }}
                      className="login-btn mb-0 ml-2"
                    />
                    <Button
                      label="Sign up"
                      type="submit"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                      className="proposal-btn mb-0 ml-2"
                    />
                </>
              )
            }

            {
              history.location.pathname.includes("login")
               && (
                <>
                  <div className="desktopView">
                    <Button
                      label="Sign up"
                      type="submit"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                      className="btn-blue main_btn"
                    />
                  </div>
                  <div className="mobileView ml-auto">
                    <Button
                      label="Sign up"
                      type="submit"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                      className="proposal-btn"
                    />
                  </div>
                </>
              )
            }
            
          </>
        )}
      </div>

      <React.Fragment>
        {openNav && <MobileNavbar closeNav={closeNav} />}
      </React.Fragment>
    </React.Fragment>
  );
};
export default Navbar;
